import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private terminateSubject = new Subject<string>();
  terminate$ = this.terminateSubject.asObservable();

  private questionSubject = new Subject<string>();
  questionsList$ = this.questionSubject.asObservable();

  private taskIdSubject = new Subject<string>();
  taskId$ = this.taskIdSubject.asObservable();

  private sideBarSubject = new Subject<any>();
  sidebar$ = this.sideBarSubject.asObservable();

  private topbarSubject = new Subject<any>();
  tobarClicked$ = this.topbarSubject.asObservable();

  private showRemoveSubject = new BehaviorSubject<boolean>(false);
  showRemove$ = this.showRemoveSubject.asObservable();

  private showResultSubject = new BehaviorSubject<boolean>(false);
  showResult$ = this.showResultSubject.asObservable();

  private hideConnectionSubject = new BehaviorSubject<boolean>(false);
  hideConnection$ = this.hideConnectionSubject.asObservable();

  constructor(private route:ActivatedRoute){}

  getTokenFromUrl(){
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('token') || '';
  }

  terminate(obj: any): void {
    this.terminateSubject.next(obj);
  }

  questionsList(data:any){
    this.questionSubject.next(data);
  }

  taskId(id:string){
    this.taskIdSubject.next(id);
  }

  sidebarClosed(isClosed:Boolean){
    this.sideBarSubject.next(isClosed)
  }

  topbarClicked(){
    this.topbarSubject.next(true);
  }

  updateShowRemove(show: boolean): void {
    this.showRemoveSubject.next(show);
  }

  updateShowResult(show: boolean): void {
    this.showResultSubject.next(show);
  }

  updateConnection(show: boolean): void {
    this.hideConnectionSubject.next(show);
  }

  totalQuestionsLeft(totalQuestions:any){
    const count = totalQuestions.filter((q: any) => !q.answerGiven?.trim()).length;
    let description = ''
    switch (count) {
      case 0:
        description = '';
        break;
      case 1:
        description = 'There is still 1 question that you haven\'t answered';
        break;
      default:
        description = `There are still ${count} questions that you haven't answered.`;
    }
    return {count,description}
  }

  calculateTimeRemaining(testStartTime:string,durationInMinutes:number) {
    if(!testStartTime && durationInMinutes){
      return durationInMinutes === 1 ? `${durationInMinutes} min remaining` : `${durationInMinutes} mins remaining`;
    }
    const updatedAtDate = new Date(testStartTime);
    const durationInMilliseconds = durationInMinutes * 60 * 1000;

    const updatedAtPlusDuration = new Date(updatedAtDate.getTime() + durationInMilliseconds);
    const currentTime = new Date();

    const timeDifference = updatedAtPlusDuration.getTime() - currentTime.getTime();
    if (timeDifference > 0) {
      const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
      const seconds = Math.floor((timeDifference / 1000) % 60);
      const minuteText = minutes === 1 ? '1 min' : `${minutes} mins`;
      const secondText = seconds === 1 ? '1 sec' : `${seconds} secs`;
      return `${minuteText} ${secondText} remaining`;
    } else {
      return 'Time is up';
    }
  }

  calculateTimer(testStartTime: string, durationInMinutes: number): number {
    const startDate = new Date(testStartTime);
    const durationInMilliseconds = durationInMinutes * 60 * 1000;
    const endTime = new Date(startDate.getTime() + durationInMilliseconds);
    const currentTime = new Date();
    const timeDifference = endTime.getTime() - currentTime.getTime();
    if (timeDifference > 0) {
        return Math.floor(timeDifference / 1000);
    }
    return 0;
  }

  goToTop(){
    (function smoothscroll() {
          const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
      })();
  }

}
